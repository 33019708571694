import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { apiClient } from "../../API/apiClient";
import { BASEURL } from "../../constants/constants";
import Project from "./Project";

const HomeComponent = ({ saveProject }) => {
  const [projectList, setProjectList] = useState([]);
  const [cookie, setCookie] = useCookies(["token"]);
  const unAuthorizedErrorRef = useRef(true);

  useEffect(() => {
    if (!unAuthorizedErrorRef.current) {
      setTimeout(() => {
        unAuthorizedErrorRef.current = true;
      }, 5000);
    }
  }, [unAuthorizedErrorRef]);

  const fetchRefreshToken = async (refreshToken) => {
    const refresh = cookie.token.refreshToken;

    await axios
      .post(BASEURL + "/token/refresh/", { refresh: refresh })
      .then((res) => {
        const token = {
          accessToken: res.data.access,
          refreshToken: res.data.refresh,
        };
        console.log("Refresh Success", token.accessToken);
        setCookie("token", token, { path: "/" });
      })
      .catch((error) => {
        if (unAuthorizedErrorRef.current) {
          console.log("Refresh Err:", error);
        }

        unAuthorizedErrorRef.current = false;
        setCookie("token", null, { path: "/" });
        return Promise.reject(error);
      });
  };

  apiClient.interceptors.response.use(
    (response) => response,
    async function (error) {
      const prevRequest = error?.config;
      console.log("Interceptor axios");

      if (error?.response?.status === 401) {
        await fetchRefreshToken();
        prevRequest.headers = {
          "Content-type": "application/json",
          Authorization: `JWT ${cookie.token.accessToken}`,
        };

        console.log("prev req:", prevRequest);
        return apiClient.request(prevRequest);
      }
      return Promise.reject(error);
    }
  );

  const fetchProjectList = (projectUsersFiltered) => {
    console.log("Filtered projectUsers is right?", projectUsersFiltered);
    apiClient
      .get(`/projects/`)
      .then((res) => {
        console.log("GET project list: ", res);
        const filteredProjects = res.data.filter(({ id }) => {
          return projectUsersFiltered.some(
            (projectUser) => projectUser.project_id === id
          );
        });
        console.log("Filtered project List:", filteredProjects);
        setProjectList(filteredProjects);
      })
      .catch((err) => {
        console.log("Project got catch Err: ", err);
      });
  };

  const fetchProjectUserList = () => {
    apiClient
      .get(`/project_users/`, {
        headers: { Authorization: `JWT ${cookie.token.accessToken}` },
      })
      .then((res) => {
        const data = res.data;
        console.log("Get project users:", data);
        const userID = Number(cookie.user_id);
        const filteredUsersByUserID = data.filter((projectUser) => {
          return projectUser.res_id === userID;
        });
        console.log("Filtered project users:", filteredUsersByUserID);
        fetchProjectList(filteredUsersByUserID);
      })
      .catch((err) => {
        console.log("Err project users: ", err);
      });
  };

  useEffect(() => {
    fetchProjectUserList();
  }, []);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">案件名</TableCell>
            <TableCell align="center">更新日</TableCell>
            <TableCell align="center">選択</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projectList.map((project) => (
            <Project
              project={project}
              saveProject={() => saveProject}
              key={project.id}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HomeComponent;
