import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { useCookies } from "react-cookie";

const Header = ({ user, isLoggined, logout }) => {
  const [cookie] = useCookies(["token"]);

  return (
    <AppBar position="static" component="nav">
      <Toolbar>
        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
          KINACO
        </Typography>
        {isLoggined && (
          <>
            <Typography mr={1}>{user.name}さん</Typography>
            {/* <Typography mr={1}>ID: {user.id}</Typography> */}
            <Button color="inherit" onClick={() => logout()}>
              LOGOUT
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
