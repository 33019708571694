import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { apiClient } from "../../API/apiClient";
import ResNewDialog from "./ResNewDialog";
import ResUser from "./ResUser";

// demo
// const userListDemo = [
//   {
//     id: 1,
//     name: "吉田隼生",
//     email: "hayao@ichinoyallc.jp",
//     authority: 2,
//     clearances: [0, 1, 2, 3, 4],
//     expiration_date: null,
//     expiration_flag: false,
//     memo: "memo",
//   },
//   {
//     id: 2,
//     name: "萱沼結衣",
//     email: "numa@ichinoyallc.jp",
//     authority: 1,
//     clearances: [],
//     expiration_date: null,
//     expiration_flag: false,
//     memo: "memo",
//   },
//   {
//     id: 3,
//     name: "亀井政孝",
//     email: "masa@ichinoyallc.jp",
//     authority: 1,
//     clearances: [0, 1, 2, 3, 4, 5, 6],
//     expiration_date: new Date("2023/3/31"),
//     expiration_flag: true,
//     memo: "memo",
//   },
//   {
//     id: 4,
//     name: "大村直毅",
//     email: "naoki@ichinoyallc.jp",
//     authority: 1,
//     clearances: [4, 5, 6],
//     expiration_date: new Date("2022/2/28"),
//     expiration_flag: true,
//     memo: "memo",
//   },
// ];

const ResManagement = ({}) => {
  const email_search = useRef();
  const [isNewResOpen, setIsNewResOpen] = useState(false);
  const [currentUserList, setCurrentUserList] = useState([
    {
      name: "",
      email: "",
      authority: 0,
      clearances: "",
      expiration_date: "",
      expiration_flag: false,
      memo: "",
    },
  ]);
  const [userList, setUserList] = useState([
    {
      name: "",
      email: "",
      authority: 0,
      clearances: "",
      expiration_date: "",
      expiration_flag: false,
      memo: "",
    },
  ]);

  const fetchUserList = () => {
    apiClient
      .get("/users/")
      .then((res) => {
        console.log("Get users: ", res.data);
        setUserList(res.data);
        setCurrentUserList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const handleClickEmailSearch = () => {
    const emailKey = email_search.current.value;

    if (emailKey === "") {
      setCurrentUserList(userList);
      return;
    }

    const searchedUserList = userList.filter((user) => {
      console.log(user.email.match(emailKey));
      return user.email.match(emailKey);
    });

    setCurrentUserList(searchedUserList);
  };

  const handleSubmitEmailSearch = (e) => {
    e.preventDefault();
    handleClickEmailSearch();
  };

  const handleCloseNewRes = () => {
    setIsNewResOpen(false);
  };

  return (
    <Box className="resManagement">
      <Box className="header">
        <Box className="searches">
          <form onSubmit={handleSubmitEmailSearch}>
            <TextField
              placeholder="メールアドレス検索"
              className="email_input input"
              inputRef={email_search}
            />
            <Button
              className="email_button button"
              variant="outlined"
              color="inherit"
              onClick={handleClickEmailSearch}
            >
              絞り込み
            </Button>
          </form>
        </Box>
        <Box className="buttons">
          <Button
            variant="outlined"
            onClick={() => {
              setIsNewResOpen(true);
            }}
          >
            RES作成
          </Button>
        </Box>
      </Box>
      <ResNewDialog
        isNewResOpen={isNewResOpen}
        handleCloseNewRes={handleCloseNewRes}
        fetchUserList={fetchUserList}
      />

      <Table className="table">
        <TableHead>
          <TableRow>
            <TableCell align="center">編集</TableCell>
            <TableCell align="center">氏名</TableCell>
            <TableCell align="center">メールアドレス</TableCell>
            <TableCell align="center">パスワード</TableCell>
            <TableCell align="center">対応可能工程</TableCell>
            <TableCell align="center">最終編集日</TableCell>
            <TableCell align="center">アーカイブ</TableCell>
            <TableCell align="center">備考メモ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentUserList.map((user) => (
            <ResUser user={user} fetchUserList={fetchUserList} />
          ))}
        </TableBody>
      </Table>
      {currentUserList.length === 0 && "ユーザーが存在しません。"}
    </Box>
  );
};

export default ResManagement;
