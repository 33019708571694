import { Slider } from "@mui/material";
import React, { useContext } from "react";
import {
  GetServerDataContext,
  SetStatusContext,
  StatusContext,
} from "./ProjectDetail";

const marks = [
  {
    value: 0,
    label: "NO",
  },
  {
    value: 1,
    label: "NOかも",
  },
  {
    value: 2,
    label: "YESかも",
  },
  {
    value: 3,
    label: "YES",
  },
];

const RadioModuleComponent = ({ proposition }) => {
  const serverData = useContext(GetServerDataContext);
  const status = useContext(StatusContext);
  const setStatus = useContext(SetStatusContext);

  const childInt = (serverData.children["ChildInt"] ?? []).filter((child) => {
    return (
      child.propositions_id === proposition.id &&
      child.sources_id === serverData.sources[serverData.source_index].id
    );
  });

  const handleChange = (e) => {
    setStatus({
      ...status,
      answerInt: {
        ...status.answerInt,
        [proposition.id]: {
          ...status.answerInt[proposition.id],
          value: e.target.value,
        },
      },
    });
  };

  return (
    <Slider
      className="slider"
      aria-label="slider"
      step={null}
      defaultValue={childInt[0] ? childInt[0].value : 0}
      onChange={(e) => handleChange(e)}
      value={
        status.answerInt[proposition.id] &&
        status.answerInt[proposition.id].value
      }
      max={3}
      marks={marks}
      valueLabelDisplay="off"
      track={false}
    />
  );
};

export default RadioModuleComponent;
