import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import * as React from "react";

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <CloseIcon
      onClick={() => closeSnackbar(snackbarKey)}
      sx={{ cursor: "pointer" }}
    />
  );
}

export default SnackbarCloseButton;
