import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { BASEURL } from "../constants/constants";

export const apiClient = axios.create({
  baseURL: BASEURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const ApiClientProvider = ({ children }) => {
  const [cookie, setCookie] = useCookies(["token"]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const unAuthorizedErrorRef = useRef(true);

  useEffect(() => {
    if (!unAuthorizedErrorRef.current) {
      setTimeout(() => {
        unAuthorizedErrorRef.current = true;
      }, 5000);
    }
  }, [unAuthorizedErrorRef]);

  useEffect(() => {
    const fetchRefreshToken = async () => {
      const refresh = cookie.token.refreshToken;

      await axios
        .post(BASEURL + "/token/refresh/", { refresh: refresh })
        .then((res) => {
          const token = {
            accessToken: res.data.access,
            refreshToken: res.data.refresh,
          };
          console.log("Refresh Success", token.accessToken);
          setCookie("token", token, { path: "/" });
        })
        .catch((error) => {
          if (unAuthorizedErrorRef.current) {
            enqueueSnackbar(
              "認証情報が正しくありません、もう一度お試しください",
              { variant: "warning", autoHideDuration: 5000 }
            );
          }

          unAuthorizedErrorRef.current = false;
          setCookie("token", null, { path: "/" });
          return Promise.reject(error);
        });
    };

    const requestInterceptors = apiClient.interceptors.request.use((config) => {
      const token = cookie.token.accessToken;
      console.log("Token is:", token);

      if (token) {
        config.headers.Authorization = `JWT ${token}`;
      }

      return config;
    });

    const responseInterceptor = apiClient.interceptors.response.use(
      (response) => response,
      async function (error) {
        const prevRequest = error?.config;
        console.log("Interceptor axios");

        if (error?.response?.status === 401) {
          await fetchRefreshToken();
          prevRequest.headers = {
            "Content-type": "application/json",
            Authorization: `JWT ${cookie.token.accessToken}`,
          };

          console.log("prev req:", prevRequest);
          return apiClient.request(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      apiClient.interceptors.request.eject(requestInterceptors);
      apiClient.interceptors.response.eject(responseInterceptor);
    };
  }, [cookie]);

  return <>{children}</>;
};
