import BookmarkIcon from "@mui/icons-material/Bookmark";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Checkbox,
  Chip,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { apiClient } from "../../API/apiClient";
import {
  CHIP_COLOR_LIST,
  CHIP_LIST,
  CHIP_NAME_LIST,
} from "../../constants/constants";

const ResUser = ({ user, fetchUserList }) => {
  const userNameRef = useRef();
  const userEmailRef = useRef();
  const userMemoRef = useRef();
  const userAuthorityRef = useRef();
  const [isEdited, setIsEdited] = useState(false);
  const [oldUserClearances, setOldUserClearances] = useState([]);
  const [userClearances, setUserClearances] = useState("");
  const [userClearanceIds, setUserClearanceIds] = useState([]);
  const [expiration, setExpiration] = useState({
    date: new Date(),
    flag: user.expiration_flag,
  });

  useEffect(() => {
    userNameRef.current = user.name;
    userEmailRef.current = user.email;
    userMemoRef.current = user.memo;
  }, []);

  useEffect(() => {
    getActiveClearances();
    setExpiration({
      date: new Date(user.expiration_date),
      flag: user.expiration_flag,
    });
    userAuthorityRef.current = user.authority;
  }, [user]);

  const displayChips = (clearances, index) => {
    const isChipsActive = !(clearances.includes(index+1) || isEdited);
    return (
      <Chip
        label={CHIP_NAME_LIST[index]}
        className="chip"
        color={clearances.includes(index+1) ? CHIP_COLOR_LIST[index] : "default"}
        variant={clearances.includes(index+1) ? "contained" : "outlined"}
        disabled={isChipsActive}
        onClick={isEdited ? () => handleClickChip(index) : null}
        sx={{
          mt: 1,
          ml: 1,
        }}
      />
    );
  };

  const handleClickEdit = () => {
    setIsEdited(true);
  };

  const getActiveClearances = () => {
    apiClient
      .get(`/user_clearance/${user.id}/`)
      .then((res) => {
        console.log("Get active clearances", res);
        
        setUserClearanceIds(res.data.map(v => v.id))
        setUserClearances(res.data.map(v => v.clearance_id));
        setOldUserClearances(res.data.map(v => v.clearance_id))
      })
      .catch((err) => {
        console.log("Err GET user_clearances :", err);
      });
  }

  const createUserClearances = (clearances) => {
    apiClient
      .post(`/user_clearances/`, clearances)
      .then((res) => {
        console.log("Create new clearances", res);
      })
      .catch((err) => {
        console.log("Err POST user_clearances :", err);
      });
  }

  const updateUserClearances = (clearances) => {
    apiClient
      .patch(`/user_clearances/`, clearances)
      .then((res) => {
        console.log("Update existing clearances", res);
      })
      .catch((err) => {
        console.log("Err PATCH user_clearances :", err);
      });
  }

  const putUpdateUser = (updateUser) => {
    apiClient
      .put(`/user/${user.id}/`, updateUser)
      .then((res) => {
        console.log("Put update user:", res);

        fetchUserList();
        setIsEdited(false);
      })
      .catch((err) => {
        console.log("Err PUT user :", err);
      });
  };

  const handleClickSave = () => {
    const updateUser = {
      name: userNameRef.current.value,
      email: userEmailRef.current.value,
      authority: userAuthorityRef.current,
      clearances: userClearances.join(),
      expiration_date: expiration.date,
      expiration_flag: expiration.flag,
      memo: userMemoRef.current.value,
    };

    console.log("UPDATE USER:", updateUser);

    putUpdateUser(updateUser);

    const newUserClearances = userClearances.filter(clearance => !oldUserClearances.includes(clearance));
    console.log(newUserClearances);
    createUserClearances(newUserClearances.map(clearance => {
      return {
        user_id: user.id,
        clearance_id: clearance,
        is_deleted: false
      }
    }));
    const deletedUserClearanceIds = userClearanceIds.filter((id, idx) => !userClearances.includes(oldUserClearances[idx]));
    updateUserClearances(deletedUserClearanceIds.map(id => {
      return {
        id: id,
        is_deleted: true
      }
    }));
  };

  const handleClickChip = (index) => {
    setUserClearances((oldClearances) => {
      let newClearances = [];
      if (oldClearances.includes(index+1)) {
        newClearances = oldClearances.filter((oldIndex) => oldIndex !== index+1);
        if (index === 0) {
          userAuthorityRef.current = 1;
        }
      } else {
        newClearances = [...oldClearances, index+1];
        if (index === 0) {
          userAuthorityRef.current = 2;
        }
      }
      return newClearances;
    });
  };

  const handleClickExpire = () => {
    setExpiration((oldExpiration) => {
      if (oldExpiration.flag) {
        return {
          flag: false,
          date: "",
        };
      } else {
        return {
          flag: true,
          date: new Date(),
        };
      }
    });
  };

  return (
    <>
      {isEdited ? (
        <TableRow className="user_row editing">
          <TableCell align="center" className="edit">
            <Button
              variant="outlined"
              color="success"
              onClick={handleClickSave}
              endIcon={<BookmarkIcon />}
            >
              保存
            </Button>
          </TableCell>
          <TableCell align="center" className="name">
            <TextField inputRef={userNameRef} defaultValue={user.name} />
          </TableCell>
          <TableCell align="center" className="email">
            <TextField inputRef={userEmailRef} defaultValue={user.email} />
          </TableCell>
          <TableCell align="center">
            <Button variant="outlined" disabled>
              再設定URL
            </Button>
          </TableCell>
          <TableCell align="center" className="clearances">
            {CHIP_LIST.map((index) => {
              return displayChips(userClearances, index);
            })}
          </TableCell>
          <TableCell align="center">
            {expiration.date &&
              `${expiration.date.getFullYear()}年${
                expiration.date.getMonth() + 1
              }月${expiration.date.getDate()}日`}
          </TableCell>
          <TableCell align="center">
            <Checkbox checked={expiration.flag} onClick={handleClickExpire} />
          </TableCell>
          <TableCell align="center" className="memo">
            <TextField inputRef={userMemoRef} defaultValue={user.memo} />
          </TableCell>
        </TableRow>
      ) : (
        <TableRow className="user_row">
          <TableCell align="center" className="edit">
            <Button
              variant="outlined"
              onClick={handleClickEdit}
              endIcon={<EditIcon />}
            >
              編集
            </Button>
          </TableCell>
          <TableCell align="center" className="name">
            {user.name}
          </TableCell>
          <TableCell align="center" className="email">
            {user.email}
          </TableCell>
          <TableCell align="center">
            <Button variant="outlined" disabled>
              再設定URL
            </Button>
          </TableCell>
          <TableCell align="center" className="clearances">
            {CHIP_LIST.map((index) => {
              return displayChips(userClearances, index);
            })}
          </TableCell>
          <TableCell align="center">
            {expiration.flag &&
              `${expiration.date.getFullYear()}年${
                expiration.date.getMonth() + 1
              }月${expiration.date.getDate()}日`}
          </TableCell>
          <TableCell align="center">
            <Checkbox checked={user.expiration_flag} disabled />
          </TableCell>
          <TableCell align="center" className="memo">
            <Typography>{user.memo}</Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ResUser;
