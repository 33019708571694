import { Button, TableCell, TableRow } from "@mui/material";
import React from "react";

const openSubWindow = (project_id) => {
  window.open("/project/" + project_id, "", "width=400, height=1080");
};

const PostComponent = ({ project, saveProject }) => {
  const createDate = new Date(project.create_date);
  const selectProject = (project) => {
    saveProject(project);
    openSubWindow(project.id);
  };

  return (
    <TableRow>
      <TableCell align="center">{project.id}</TableCell>
      <TableCell align="center">{project.name}</TableCell>
      <TableCell align="center">{createDate.toLocaleString("ja-JP")}</TableCell>
      <TableCell align="center">
        <Button variant="contained" onClick={() => selectProject(project)}>
          回答する
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default PostComponent;
