import { Box, Button, ButtonGroup } from "@mui/material";
import React, { useContext } from "react";
import { GetServerDataContext, SetStatusContext } from "./ProjectDetail";

const ArticleComponent = ({ openerChange }) => {
  const serverData = useContext(GetServerDataContext);
  const setStatus = useContext(SetStatusContext);

  // const clikedArticle = (index) => {
  //   setStatus({
  //     answerStr: {},
  //     answerInt: {},
  //   });
  // };

  return (
    <Box className="article_buttons" sx={{}}>
      <ButtonGroup>
        <Button
          variant="contained"
          className="open_button"
          onClick={() => openerChange(serverData.source_index)}
        >
          記事を開く
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default ArticleComponent;
