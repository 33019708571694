import { Slider } from "@mui/material";
import React from "react";

const marks = [
  {
    value: 0,
    label: "NO",
  },
  {
    value: 1,
    label: "NOかも",
  },
  {
    value: 2,
    label: "YESかも",
  },
  {
    value: 3,
    label: "YES",
  },
];

const RadioModuleComponent = ({ proposition }) => {
  return (
    <Slider
      className="slider"
      aria-label="slider"
      step={null}
      defaultValue={0}
      max={3}
      marks={marks}
      valueLabelDisplay="off"
      track={false}
    />
  );
};

export default RadioModuleComponent;
