import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { arrayMoveImmutable } from "array-move";
import { useState } from "react";
import QuestionBlock from "./QuestionBlock";

const Section = ({
  section,
  section_index,
  handleClickAddQuestion,
  deleteQuestion,
  changeQuestion,
  handleClickDeleteSection,
  changeSection,
  moveQuestion,
  currentPropositions,
  setCurrentPropositions,
}) => {
  const [isSectionEditOpen, setIsSectionEditOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(section_index);
  const handleTitleChange = (e) => {
    section.title = e.target.value;
    changeSection(section_index, section);
  };
  const handleDescriptionChange = (e) => {
    section.description = e.target.value;
    changeSection(section_index, section);
  };
  const handleCloseSectionEdit = () => {
    setIsSectionEditOpen(false);
  };
  const handleOpenSectionEdit = () => {
    setIsSectionEditOpen(true);
  };
  const handleChangeSectionNum = (e) => {
    console.log(e.target.value);
    setCurrentPropositions((prev) =>
      arrayMoveImmutable(prev, currentIndex, e.target.value)
    );
    setCurrentIndex(e.target.value);
  };
  return (
    <Box className="sectionBlock">
      <Dialog
        open={isSectionEditOpen}
        onClose={handleCloseSectionEdit}
        maxWidth={"800px"}
      >
        <DialogTitle>セクションを編集する</DialogTitle>
        <DialogContent className="sectionDialog">
          <Box className="sectionDialog__inner">
            <Box className="input__group">
              <TextField
                label="セクションタイトル"
                className="section__input"
                value={section.title}
                onChange={handleTitleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="補足"
                className="section__input"
                value={section.description}
                onChange={handleDescriptionChange}
                multiline
                maxRows={3}
                minRows={2}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Typography className="select__title">並び替え</Typography>
              <Select
                className="select"
                defaultValue={section_index}
                onChange={handleChangeSectionNum}
              >
                {currentPropositions.map((elem, index) => {
                  return (
                    <MenuItem key={index} value={index}>
                      {index + 1}番目
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            <Box className="section__list">
              {currentPropositions.map((elem, index) => {
                return (
                  <Typography>
                    No.{index + 1}:{elem.title}
                  </Typography>
                );
              })}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseSectionEdit}>
            完了
          </Button>
        </DialogActions>
      </Dialog>
      <Box className="section__titleBlock">
        <Box className="section__title">
          <Typography>セクション{section_index + 1}：</Typography>
          <Typography>
            {section.title === null
              ? "※タイトルがまだありません"
              : section.title}
          </Typography>
          <EditIcon
            color="info"
            fontSize="small"
            sx={{ cursor: "pointer", ml: "12px" }}
            onClick={() => handleOpenSectionEdit()}
          />
        </Box>
        <Button
          color="error"
          onClick={() => handleClickDeleteSection(section_index, section.id)}
        >
          セクションを削除する
        </Button>
      </Box>

      <Typography className="section__description">
        {section.description}
      </Typography>
      {section.proposition_section.map((proposition, i) => (
        <QuestionBlock
          key={i}
          index={i}
          section_index={section_index}
          proposition={proposition}
          deleteQuestion={deleteQuestion}
          changeQuestion={changeQuestion}
          moveQuestion={moveQuestion}
        />
      ))}

      <Box className="newQuestion__container">
        <Button
          variant="contained"
          onClick={() => handleClickAddQuestion(section_index)}
        >
          設問を追加する
        </Button>
      </Box>
    </Box>
  );
};

export default Section;
