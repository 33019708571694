import { TextField } from "@mui/material";
import React from "react";

const InputModule = ({ proposition }) => {
  return (
    <TextField
      className="input_text"
      variant="standard"
      color="primary"
      id={proposition.id}
      type="text"
      placeholder={"回答を入力"}
    />
  );
};

export default InputModule;
