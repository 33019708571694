import { Box, Button, Container, Typography } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { createContext, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { apiClient } from "../../API/apiClient";
import { ASSIGN_MAX_COUNT, BASEURL } from "../../constants/constants";
import Article from "./Article";
import InputPlain from "./InputPlain";
import IntEvi from "./IntEvi";
import Loading from "./Loading";
import RadioModule from "./RadioModule";
import StrEvi from "./StrEvi";

export const GetServerDataContext = createContext({
  propositions: [],
  sources: [],
});

export const StatusContext = createContext();
export const SetStatusContext = createContext();

const ProjectDetailComponent = ({ user_id, isLoggined }) => {
  const params = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isSaveButtonPushed = useRef(false);
  const isSavingErr = useRef(false);
  const isPreparedNextSourceFlag = useRef(false);
  const [sourceAry, setSourceAry] = useState([]);
  const [currentSourceID, setCurrentSourceID] = useState(0);
  const [sourceIndex, setSourceIndex] = useState(0);
  const [isGettingPropositions, setIsGettingPropositions] = useState(false);
  const [sources, setSources] = useState([
    {
      id: 0,
      project_id: 0,
      name: "",
      url: "",
      memo: "",
      number: 0,
    },
  ]);
  const [propositions, setPropositions] = useState([
    {
      id: 0,
      project_id: 0,
      module_type: 0,
      title: "",
      flag: false,
      section_num: 0,
    },
  ]);
  const [sectionsWithPropositions, setSectionsWithPropositions] = useState([
    {
      description: "",
      id: 0,
      number: 0,
      project_id: 0,
      proposition_section: [
        {
          id: 0,
          module_type: 0,
          title: "",
          flag: false,
          description: null,
          number: 0,
          section_id: 0,
        },
      ],
    },
  ]);
  const [startConfirm, setStartConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(false);
  const [sourceUsersID, setSourceUsersID] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const [children, setChildren] = useState({
    ChildInt: [{}],
    ChildText: [{}],
  });
  const [cookie, setCookie, removeCookie] = useCookies(["token"]);
  const unAuthorizedErrorRef = useRef(true);

  useEffect(() => {
    if (!unAuthorizedErrorRef.current) {
      setTimeout(() => {
        unAuthorizedErrorRef.current = true;
      }, 5000);
    }
  }, [unAuthorizedErrorRef]);

  const fetchRefreshToken = async (refreshToken) => {
    const refresh = cookie.token.refreshToken;

    await axios
      .post(BASEURL + "/token/refresh/", { refresh: refresh })
      .then((res) => {
        const token = {
          accessToken: res.data.access,
          refreshToken: res.data.refresh,
        };
        console.log("Refresh Success", token.accessToken);
        setCookie("token", token, { path: "/" });
      })
      .catch((error) => {
        if (unAuthorizedErrorRef.current) {
          console.log("Refresh Err:", error);
        }

        unAuthorizedErrorRef.current = false;
        setCookie("token", null, { path: "/" });
        return Promise.reject(error);
      });
  };

  apiClient.interceptors.response.use(
    (response) => response,
    async function (error) {
      const prevRequest = error?.config;
      console.log("Interceptor axios");

      if (error?.response?.status === 401) {
        await fetchRefreshToken();
        prevRequest.headers = {
          "Content-type": "application/json",
          Authorization: `JWT ${cookie.token.accessToken}`,
        };

        console.log("prev req:", prevRequest);
        return apiClient.request(prevRequest);
      }
      return Promise.reject(error);
    }
  );

  const proceedToNextSource = () => {
    fetchSourceAry();
    setIsLoading(false);
    setProgress(false);
    setStartConfirm(false);
    setTimeout(() => {
      setIsLoading(true);
    }, 1000);
  };

  const StatusProvider = ({ children }) => {
    const [status, setStatus] = useState({
      answerStr: {},
      answerInt: {},
      Err: {},
    });

    const handleSaveAnswer = async () => {
      isSavingErr.current = false;
      if (isSaveButtonPushed.current) {
        console.log("Save button is already pushed");
        return;
      }
      isSaveButtonPushed.current = true;
      setTimeout(() => {
        isSaveButtonPushed.current = false;
      }, 3000);
      const validated = await validateAnswer(sources[sourceIndex].id); // boolean
      if (validated.flag) {
        console.log("SUCCESS: Answer validated");
        await saveAnswer(
          sources[sourceIndex].id,
          validated.validatedIntAry,
          validated.validatedStrAry
        );
        preparedNextSource();
      } else {
        console.log("ERROR: Answer validated");
        enqueueSnackbar(
          "回答が正しい形式ではありませんでした、もう一度ご確認ください。",
          {
            variant: "error",
            autoHideDuration: 5000,
          }
        );
      }
    };

    const preparedNextSource = () => {
      if (!isPreparedNextSourceFlag.current) {
        setTimeout(() => {
          preparedNextSource();
        }, 1000);
      } else {
        isPreparedNextSourceFlag.current = false;
        if (!isSavingErr.current) {
          console.log(
            "Saving is success, Go to next source",
            isSavingErr.current
          );
          putFlagSourceUsers();
        } else {
          console.log("Saving is not success, try again", isSavingErr.current);

          enqueueSnackbar(
            "回答保存中にエラーが発生しました、もう一度お試しください。",
            {
              variant: "error",
              autoHideDuration: 2500,
            }
          );
        }
      }
    };
    const validateAnswer = (source_id) => {
      let error = {};
      let strAry = { ...status.answerStr };
      let intAry = { ...status.answerInt };
      let validated = true;

      console.log("Current Value:", strAry, intAry);

      sectionsWithPropositions.map((section) => {
        section.proposition_section.map((proposition) => {
          if (proposition.module_type === 2) {
            if (strAry[proposition.id] === undefined) {
              // validated = false;
              // error = {
              //   ...error,
              //   [proposition.id]: {
              //     ...error[proposition.id],
              //     value: {
              //       message: "回答が空欄です。",
              //     },
              //   },
              // };
              strAry[proposition.id] = {
                ...strAry[proposition.id],
                value: "none",
              };
            }
          } else if (proposition.module_type === 1) {
            if (intAry[proposition.id] === undefined) {
              intAry[proposition.id] = {
                value: 0,
              };
            } else if (intAry[proposition.id].value === undefined) {
              intAry[proposition.id] = {
                ...intAry[proposition.id],
                value: 0,
              };
            }
          }
        });
      });

      for (let key in strAry) {
        if (strAry[key].value !== "none") {
          if (
            strAry[key].evidence === undefined ||
            strAry[key].evidence === ""
          ) {
            validated = false;
            error = {
              ...error,
              [key]: {
                ...error[key],
                evidence: {
                  message: "回答を記述した場合、その根拠を入力してください。",
                },
              },
            };
          }
        }
      }

      for (let key in intAry) {
        if (intAry[key].value === 2 || intAry[key].value === 3) {
          if (
            intAry[key].evidence === undefined ||
            intAry[key].evidence === ""
          ) {
            validated = false;
            error = {
              ...error,
              [key]: {
                ...error[key],
                evidence: {
                  message:
                    "YES、またはYESかもを選択した場合は申送を入力してください。",
                },
              },
            };
          }
        }
        if (intAry[key].value === 1 || intAry[key].value === 2) {
          if (intAry[key].message === undefined || intAry[key].message === "") {
            validated = false;
            console.log("before Yeskamo", error);
            error = {
              ...error,
              [key]: {
                ...error[key],
                message: {
                  message:
                    "YESかも、またはNOかもを選択した場合は申送を入力してください。",
                },
              },
            };
          }
        }
      }

      console.log("validated int ary", intAry);

      setStatus({
        ...status,
        answerInt: intAry,
        Err: error,
      });

      const result = {
        validatedIntAry: intAry,
        validatedStrAry: strAry,
        flag: validated,
      };
      return result;
    };

    const saveAnswer = (source_id, intAry, strAry) => {
      console.log("save the Answer", status.answerInt, status.answerStr);

      const getChildText = async (proposition_id, result) => {
        console.log("Getting Text now");
        try {
          const res = await apiClient.get(
            BASEURL +
              `/childText/getSpecificChildText/${source_id}/${proposition_id}/${cookie.user_id}/`
          );
          console.log("There is childText:", res.data.length !== 0, res);
          await putChildText(res.data.id, result);
        } catch (err) {
          if (
            err.response.data.message ===
            "Relevant ChildText data does not exist."
          ) {
            await postChildText(result);
          } else {
            console.log("Getting childText is Err: ", err);
            isSavingErr.current = true;
            isPreparedNextSourceFlag.current = true;
          }
        }
        // console.log("Getting Text now");
        // apiClient
        //   .get(BASEURL + `/childText_sourcesId/${source_id}/`)
        //   .then((children) => {
        //     console.log(
        //       "There is childText:",
        //       children.data.length !== 0,
        //       children
        //     );
        //     if (children.data.length !== 0) {
        //       children.data.map((child) => {
        //         if (child.propositions_id === Number(proposition_id)) {
        //           putChildText(child.id, result);
        //         }
        //       });
        //     } else {
        //       postChildText(result);
        //     }
        //   })
        //   .catch((err) => {
        //     console.log("Getting childText is Err: ", err);
        //     isSavingErr.current = true;
        //   });
      };

      const putChildText = (childTextID, result) => {
        result.data_type = "text";
        result.id = childTextID;
        console.log("Putting Text now");
        apiClient
          .put(`${BASEURL}/childText/${childTextID}/`, result)
          .then((res) => {
            console.log("Putting childText is success: ", res);
          })
          .catch((err) => {
            console.log("Putting childText is Err: ", err);
            isSavingErr.current = true;
          });
      };

      const postChildText = (result) => {
        result.data_type = "text";
        result.id = uuidv4();
        console.log("Posting Text now", result);
        apiClient
          .post(BASEURL + "/childText/", result)
          .then((res) => {
            console.log("Posting childText is success: ", res);
          })
          .catch((err) => {
            console.log("Posting childText is Err: ", err);
            isSavingErr.current = true;
          });
      };

      // todo: KINGさんを待ってから保存成功アラートを実装する
      for (let key in strAry) {
        const result = {
          res_id: cookie.user_id,
          proposition_id: Number(key),
          source_id: source_id,
          value: strAry[key].value,
          evidence: strAry[key].evidence,
          message: strAry[key].message,
        };

        getChildText(key, result);

        console.log("ChildText result:", result);
      }

      const getChildInt = async (proposition_id, result) => {
        console.log("Getting Int now");
        try {
          const res = await apiClient.get(
            BASEURL +
              `/childInt/getSpecificChildInt/${source_id}/${proposition_id}/${cookie.user_id}/`
          );
          console.log("There is childInt:", res.data.length !== 0, res);
          await putChildInt(res.data.id, result);
        } catch (err) {
          if (
            err.response.data.message ===
            "Relevant ChildInt data does not exist."
          ) {
            await postChildInt(result);
          } else {
            console.log("Getting childInt is Err: ", err);
            isSavingErr.current = true;
            isPreparedNextSourceFlag.current = true;
          }
        }
        // apiClient
        //   .get(BASEURL + `/childInt_sourcesId/${source_id}/`)
        //   .then((children) => {
        //     console.log(
        //       "There is childInt:",
        //       children.data.length !== 0,
        //       children
        //     );
        //     if (children.data.length !== 0) {
        //       children.data.map((child) => {
        //         if (child.propositions_id === Number(proposition_id)) {
        //           await putChildInt(child.id, result);
        //         }
        //       });
        //     } else {
        //       await postChildInt(result);
        //     }
        //   })
        //   .catch((err) => {
        //     console.log("Getting childInt is Err: ", err);
        //     isSavingErr.current = true;
        //   });
      };

      const putChildInt = (childIntID, result) => {
        result.data_type = "int";
        result.id = childIntID;
        console.log("Putting Text now");
        apiClient
          .put(`${BASEURL}/childInt/${childIntID}/`, result)
          .then((res) => {
            console.log("Putting childInt is success: ", res);
            isPreparedNextSourceFlag.current = true;
          })
          .catch((err) => {
            console.log("Putting childInt is Err: ", err);
            isSavingErr.current = true;
            isPreparedNextSourceFlag.current = true;
          });
      };

      const postChildInt = (result) => {
        result.data_type = "int";
        result.id = uuidv4();
        console.log("Posting Int now", result);
        apiClient
          .post(BASEURL + "/childInt/", result)
          .then((res) => {
            console.log("Posting childInt is success: ", res);
            isPreparedNextSourceFlag.current = true;
          })
          .catch((err) => {
            console.log("Posting childInt is Err: ", err);
            isSavingErr.current = true;
            isPreparedNextSourceFlag.current = true;
          });
      };
      for (let key in intAry) {
        const result = {
          res_id: cookie.user_id,
          proposition_id: Number(key),
          source_id: source_id,
          value: intAry[key].value,
          evidence: intAry[key].evidence,
          message: intAry[key].message,
        };

        getChildInt(key, result);
        console.log("ChildInt result:", result);
      }

      console.log("children save!!!");
    };

    return (
      <StatusContext.Provider value={status}>
        <SetStatusContext.Provider value={setStatus}>
          {children}
          <Box className="status_buttons">
            <Button
              className="status_buttons__inner"
              variant="contained"
              onClick={() => handleSaveAnswer()}
            >
              <Typography variant="subtitle">保存</Typography>
            </Button>
          </Box>
        </SetStatusContext.Provider>
      </StatusContext.Provider>
    );
  };

  const fetchChildren = (source_id) => {
    console.log("FETCH CHILDREN");
    console.log("SOURCE_ID:", source_id);
    source_id &&
      apiClient
        .get(`/get_children/${source_id}`)
        .then((res) => {
          console.log("Children:", res.data);
          setChildren(res.data);
        })
        .catch((err) => {
          console.log("Get chidlren Err:", err);
        });
  };

  const fetchSections = () => {
    apiClient
      .get(`/get_sections_with_propositions/${params.id}`, {
        headers: { Authorization: `JWT ${cookie.token.accessToken}` },
      })
      .then((res) => {
        console.log("Get proposition with section:", res);
        setSectionsWithPropositions(res.data);
        setIsGettingPropositions(true);
      })
      .catch((err) => {
        console.log("Err proposition with section:", err);
      });
  };

  const fetchSources = () => {
    apiClient
      .get(`/sources/${params.id}`, {
        headers: { Authorization: `JWT ${cookie.token.accessToken}` },
      })
      .then((res) => {
        const result = [{ id: null }, ...res.data];
        console.log("Sources:", result);
        setSources(result);
        fetchChildren(res.data[sourceIndex].id);
      })
      .catch((err) => {
        console.log("Err getting sources : ", err);
      });
  };

  const fetchFirstSourceAry = () => {
    apiClient
      .get(
        `/sources/index_ary/${params.id}/${cookie.user_id}/${ASSIGN_MAX_COUNT}`,
        {
          headers: { Authorization: `JWT ${cookie.token.accessToken}` },
        }
      )
      .then((res) => {
        console.log("SourcesAry:", res.data);
        const new_source_users_id = res.data.source_users_id;
        console.log("Get first_source_index_list:", res.data.source_users_id);
        if (res.data.index_array.length === 0) {
          console.log("Source Ary is all clean");
          setIsFinished(true);
        }
        const resultAry = res.data.index_array;

        setSourceAry(resultAry);
        setCurrentSourceID(resultAry[0]);

        new_source_users_id.length !== 0 &&
          setSourceUsersID(new_source_users_id[0]);
      })
      .catch((err) => {
        console.log("Get sources_ary Err: ", err);
      });
  };

  const fetchSourceAry = () => {
    apiClient
      .post(`/sources/index_ary/`, {
        assign_max_count: ASSIGN_MAX_COUNT,
        index_array: sourceAry,
        res_id: cookie.user_id,
      })
      .then((res) => {
        console.log("New SourcesAry:", res);

        const resultAry = res.data.index_array;

        setSourceAry(resultAry);
        setCurrentSourceID(resultAry[0]);
        if (res.data.index_array.length === 0) {
          console.log("Source Ary is all clean");
          setIsFinished(true);
        }
      })
      .catch((err) => {
        console.log("Get new sources_ary Err: ", err);
      });
  };

  const putFlagSourceUsers = () => {
    apiClient
      .put(`/source_users/${sourceUsersID}/`, {
        res_id: cookie.user_id,
        source_id: currentSourceID,
        flag: true,
      })
      .then((res) => {
        console.log("Put sourceUsers flag:", res);

        setSourceUsersID(0);

        proceedToNextSource();
      })
      .catch((err) => {
        console.log("Err put sourceUsers flag:", err);
      });
  };

  const putAssignCount = () => {
    apiClient
      .put(`/sources/assign/${currentSourceID}/`, {
        assign_max_count: ASSIGN_MAX_COUNT,
      })
      .then((res) => {
        console.log("Put assign count up:", res);
      })
      .catch((err) => {
        if (err.response.status === 406) {
          console.log("assign MAX over", err);
          assignOverCount();
        } else {
          console.log("Err assign count up:", err);
        }
      });
  };

  const assignOverCount = () => {
    window.alert(
      "このソースは既に既定の回答数を達成しています。次のソースへと進んでください。"
    );

    proceedToNextSource();
  };

  const postSourceUsers = () => {
    apiClient
      .post(`/source_users/`, {
        source_id: sources[sourceIndex].id,
        res_id: cookie.user_id,
      })
      .then((res) => {
        console.log("Pushed source users:", res);
        setSourceUsersID(res.data.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleConfirm = () => {
    console.log("clicked start confirm button");
    setStartConfirm(true);
    openerChange(sourceIndex);

    // ソースユーザーIDが初期値以外（取得していたら）アサインカウント追加とソースユーザーをインサートしない
    if (sourceUsersID === 0) {
      putAssignCount();
      postSourceUsers();
    }
  };

  const setTimeoutProgress = (time) => {
    setTimeout(() => {
      setProgress(true);
    }, time);
  };

  useEffect(() => {
    fetchSources();
    fetchSections();
    fetchFirstSourceAry();

    !isLoading && setTimeoutProgress(500);
  }, []);

  // loading
  useEffect(() => {
    progress && setIsLoading(true);
  }, [sourceAry, progress]);

  // 　新しいソースIDに変更された時に、インデックスを変更する
  useEffect(() => {
    const currentSource = sources
      .map((source, index) => {
        if (source.id === currentSourceID) {
          return index;
        } else {
          return null;
        }
      })
      .filter((source) => source);
    console.log("currentSource:", currentSource);
    setSourceIndex(currentSource[0]);
    console.log("SOURCE_ARY is Changed:", currentSource);
  }, [currentSourceID]);

  useEffect(() => {
    sources[sourceIndex] &&
      sources[sourceIndex].id &&
      saveSourceIndex(sourceIndex);
  }, [sourceIndex]);

  const saveSourceIndex = (index) => {
    let confirm = true;
    // if (!sources[sourceIndex].Flag) {
    //   confirm = window.confirm(
    //     "回答を保存していません、ページから離れてもよろしいですか？"
    //   );
    // }

    console.log("save source", sources[index]);
    if (sources[index].id && confirm) {
      fetchChildren(sources[index].id);
    }
  };

  const AnswerModuleComponent = ({ module_type, proposition, source_id }) => {
    if (module_type === 1) {
      return (
        <>
          <RadioModule proposition={proposition} />
          <IntEvi proposition={proposition} />
        </>
      );
    } else if (module_type === 2) {
      return (
        <>
          <InputPlain proposition={proposition} />
          <StrEvi proposition={proposition} />
        </>
      );
    }
  };

  const openerChange = (index) => {
    console.log("params.id", params.id);
    if (params.id) {
      console.log("open article in opnenner window");
      window.opener.open(sources[index].url);
    }
  };

  const handleFinish = () => {
    window.close();
  };

  return (
    <Container component="detail" maxWidth="md" className="res_projectDetail">
      <GetServerDataContext.Provider
        value={{
          propositions: propositions,
          sources: sources,
          source_index: sourceIndex,
          children: children,
        }}
      >
        {!startConfirm ? (
          <Loading
            title={sources[sourceIndex] && sources[sourceIndex].name}
            isLoading={isLoading}
            isFinished={isFinished}
            handleConfirm={() => handleConfirm}
            handleFinish={() => handleFinish}
          />
        ) : (
          <StatusProvider>
            <Box className="title_block">
              <Box className="title_inner" sx={{}}>
                {sources[sourceIndex] && (
                  <Typography className="title">
                    {sources[sourceIndex].number}. {sources[sourceIndex].name}
                  </Typography>
                )}
              </Box>
              <Article openerChange={(i) => openerChange(i)} />
            </Box>
            {
              <>
                {sectionsWithPropositions.map((section, index) => (
                  <Box className="section__container" key={section.id}>
                    <Typography className="title">
                      セクション{section.number}：{section.title}
                    </Typography>
                    {section.description && (
                      <Typography className="desc">
                        {section.description}
                      </Typography>
                    )}
                    <Box className="section__inner">
                      {section.proposition_section.map(
                        (proposition, prop_index) => (
                          <Box className="question__block" key={proposition.id}>
                            <Typography className="prop-title">
                              {proposition.title}
                            </Typography>
                            {proposition.description && (
                              <Typography className="prop-description">
                                ※{proposition.description}
                              </Typography>
                            )}
                            <AnswerModuleComponent
                              module_type={proposition.module_type}
                              proposition={proposition}
                              source_id={sources[sourceIndex].id}
                            />
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                ))}
              </>
            }
          </StatusProvider>
        )}
      </GetServerDataContext.Provider>
    </Container>
  );
};

export default ProjectDetailComponent;
