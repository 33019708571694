const BASEURL = process.env.REACT_APP_BASEURL;
// const BASEURL = "http://127.0.0.1:8000/api"; //local
// const BASEURL = "https://xs020974.xsrv.jp/kinaco/api"; //xserver

const ASSIGN_MAX_COUNT = 2;

const MODE_INDEX = {
  main: 1,
  detail: 2,
  edit: 3,
  classy: 4,
  res: 5,
};

const USER_LIST = [
  "NONE",
  "KING",
  "HAYAO",
  "NUMA",
  "MASA",
  "NAOKI",
  "KENTO",
  "EISUKE",
  "ZIZI",
  "MIDORI",
  "TOTTIE",
  "GON",
  "MORI",
  "YAKU",
];

const CHIP_LIST = [0, 1, 2, 3, 4, 5, 6];

const CHIP_NAME_LIST = [
  "DM",
  "分類チェック",
  "抽出チェック",
  "要約チェック",
  "分類",
  "抽出",
  "要約",
];

const CHIP_COLOR_LIST = [
  "success",
  "primary",
  "primary",
  "primary",
  "warning",
  "warning",
  "warning",
];

export {
  ASSIGN_MAX_COUNT, BASEURL, CHIP_COLOR_LIST, CHIP_LIST,
  CHIP_NAME_LIST, MODE_INDEX, USER_LIST
};

