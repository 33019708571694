import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

const QuestionBlock = ({
  index,
  section_index,
  proposition,
  deleteQuestion,
  changeQuestion,
  moveQuestion,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const questionNum = index + 1;

  const handleChangeAnswerWay = (e) => {
    proposition.module_type = e.target.value;
    changeQuestion(index, proposition, section_index);
  };

  const handleChangeQuestionTitle = (e) => {
    proposition.title = e.target.value;
    changeQuestion(index, proposition, section_index);
  };

  const handleChangeMessage = (e) => {
    proposition.description = e.target.value;
    changeQuestion(index, proposition, section_index);
  };

  const handleClickMini = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClickDelte = () => {
    deleteQuestion(index, section_index);
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleQustionUp = () => {
    moveQuestion(-1, index, section_index);
    setAnchorEl(null);
    setIsOpen(false);
  };
  const handleQustionDown = () => {
    moveQuestion(1, index, section_index);
    setAnchorEl(null);
    setIsOpen(false);
  };
  return (
    <Box className="question">
      <Box className={isOpen ? "questionInner open" : "questionInner close"}>
        <Box className={isOpen ? "titleBlock open" : "titleBlock close"}>
          {!isOpen ? (
            <Box className="titleInner">
              <Typography>設問{questionNum}：</Typography>
              <Typography className="QuestionTitle">
                (
                {proposition.module_type === 1
                  ? "スコア"
                  : proposition.module_type === 2
                  ? "記述式"
                  : "未選択"}
                )...
                {proposition.title}
              </Typography>
            </Box>
          ) : (
            <Typography>設問{questionNum}：</Typography>
          )}
          <Box className="buttonGroup">
            <Button onClick={handleClickMini}>
              {!isOpen ? "Open" : "Close"}
            </Button>

            <Box className="MenuGruop">
              <MoreVertIcon
                id="basic-button"
                aria-controls={isMenuOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuOpen ? "true" : undefined}
                onClick={handleClickMenu}
              />
              <Menu
                id="basic-menu"
                aria-labelledby="demo-positioned-button"
                open={isMenuOpen}
                onClose={handleMenuClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem onClick={handleClickDelte}>削除する</MenuItem>
                <MenuItem onClick={handleQustionUp}>一つ上へ</MenuItem>
                <MenuItem onClick={handleQustionDown}>一つ下へ</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
        {isOpen && (
          <Box className="question__content">
            <InputLabel id="selectAnsweWayLabel">
              <Typography variant="h6">回答方法は？</Typography>
            </InputLabel>
            <Box className="selectAnsweWayGroup" sx={{ mt: "12px" }}>
              <Select
                labelId="selectAnsweWayLabel"
                sx={{ width: "400px" }}
                value={proposition.module_type}
                onChange={handleChangeAnswerWay}
              >
                <MenuItem value={1}>選択式（スコア）</MenuItem>
                <MenuItem value={2}>抜粋・自由記述</MenuItem>
              </Select>
            </Box>
            <InputLabel sx={{ mt: "12px" }}>
              <Typography variant="h6">質問事項は？</Typography>
            </InputLabel>
            <Box className="questionTitleGroup" sx={{ mt: "12px" }}>
              <TextField
                sx={{ width: "400px" }}
                multiline
                minRows={2}
                maxRows={2}
                placeholder="※ここに質問内容を入力する"
                value={proposition.title}
                onChange={handleChangeQuestionTitle}
              />
            </Box>
            <InputLabel sx={{ mt: "12px" }}>
              <Typography variant="h6">補足事項は？</Typography>
            </InputLabel>
            <Box className="questionTitleGroup" sx={{ mt: "12px" }}>
              <TextField
                sx={{ width: "400px" }}
                multiline
                minRows={2}
                maxRows={2}
                placeholder="※補足があればここに入力する"
                value={proposition.description}
                onChange={handleChangeMessage}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default QuestionBlock;
