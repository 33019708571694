import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.scss";
import DeliveryManager from "./components/DM/DeliveryManager";
import Header from "./components/Header";
import Login from "./components/Login/Login";
import Home from "./components/RES/Home";
import ProjectDetail from "./components/RES/ProjectDetail";
import { BASEURL } from "./constants/constants";

const App = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [user, setUser] = useState({
    id: 0,
    name: "",
    email: "",
  });
  const [project, setProject] = useState({});
  const [isLoggined, setIsLoggined] = useState(true);
  const [cookie, setCookie, removeCookie] = useCookies([
    "user_authority",
    "user_id",
  ]);
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 650,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });

  const getRefresh = () => {
    console.log(cookie.token);
    axios
      .post(BASEURL + "/token/refresh/", { refresh: cookie.token.refreshToken })
      .then((res) => {
        console.log("Get refresh:", res);
        const token = {
          accessToken: res.data.access,
          refreshToken: res.data.refresh,
        };
        setCookie("token", token, { path: "/" });
        setIsLoggined(true);
        enqueueSnackbar("認証情報の更新に成功しました。", {
          variant: "success",
          autoHideDuration: 5000,
        });
      })
      .catch((err) => {
        console.log("Err post refresh:", err);
        removeCookie("token");
        setIsLoggined(false);
        console.log("refresh token is valid");
      });
  };

  const verifyToken = () => {
    if (cookie.token && !isLoggined) {
      axios
        .post(BASEURL + "/token/verify/", {
          token: cookie.token.accessToken,
        })
        .then((res) => {
          console.log("Get Verify", res);
          setIsLoggined(true);
        })
        .catch((err) => {
          if (err.response.status === 401 && cookie.token.refreshToken) {
            console.log("You should Refresh");
            getRefresh();
          } else {
            setIsLoggined(false);
            console.log("Cannot refresh in App", err);
          }
          console.log("Err verify:", err);
        });
    }
  };

  const fetchAuthority = async (user_id, accessToken) => {
    if (cookie.token) accessToken = cookie.token.accessToken;
    await axios
      .get(BASEURL + `/user/${user_id}/`, {
        headers: {
          Authorization: `JWT ${accessToken}`,
        },
      })
      .then((res) => {
        console.log("Get authority:", res);
        setCookie("user_authority", res.data.authority, { path: "/" });
        // setCookie("user_authority", 2, { path: "/" });
        setUser(res.data);
        setIsLoggined(true);
      })
      .catch((err) => {
        console.log("Err authority:", err);
      });
  };

  useEffect(() => {
    verifyToken();

    if (user.id === 0 && cookie.token) {
      fetchAuthority(cookie.user_id);
    }
  }, []);

  const saveProject = (newProject) => {
    setProject({ newProject });
    console.log("save project");
    console.log(newProject);
  };

  const logout = () => {
    removeCookie("token");
    removeCookie("user_id");
    removeCookie("user_authority");
    setIsLoggined(false);
  };

  useEffect(() => {
    console.log("IsLoggined is changed", isLoggined);
    if (!isLoggined) {
      navigate("/login");
    }
  }, [isLoggined]);

  const AuthorityProvider = () => {
    console.log("This is member");
    if (cookie.user_authority === "1") {
      console.log("A Researcher");
      return <Home saveProject={(p) => saveProject(p)} />;
    } else if (cookie.user_authority === "2") {
      console.log("A DeliveryManager");
      return <DeliveryManager isLoggined={isLoggined} />;
    } else {
      console.log("Err: Authority not found");
      return <>ERR: AUTHORITY IS NOT FOUND</>;
    }
  };

  return (
    <div className="container">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header user={user} isLoggined={isLoggined} logout={() => logout()} />
        <Routes>
          <Route path="/" element={<AuthorityProvider />} />
          <Route
            path="/project/:id"
            element={
              <ProjectDetail
                project={project}
                user_id={user.id}
                isLoggined={isLoggined}
              />
            }
          />
          <Route
            path="/login"
            element={
              <Login
                isLoggined={isLoggined}
                setIsLoggined={setIsLoggined}
                setUser={setUser}
                fetchAuthority={fetchAuthority}
              />
            }
          />
        </Routes>
      </ThemeProvider>
    </div>
  );
};

export default App;
