import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

const ClassyTitleInfo = ({ project, createDate, handleClickSaveQuestions }) => {
  return (
    <Box className="titleInfo__container" sx={{}}>
      <Box className="titleInfo__inner">
        <Typography>案件名：{project.name}</Typography>
        <Typography>作成日：{createDate.toLocaleString("ja-JP")}</Typography>
      </Box>
      <Button
        variant="outlined"
        size="large"
        onClick={handleClickSaveQuestions}
      >
        情報を保存する
      </Button>
    </Box>
  );
};

export default ClassyTitleInfo;
