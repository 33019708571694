import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { apiClient } from "../../API/apiClient";
import {
  CHIP_COLOR_LIST,
  CHIP_LIST,
  CHIP_NAME_LIST,
} from "../../constants/constants";

const ResNewDialog = ({ isNewResOpen, handleCloseNewRes, fetchUserList }) => {
  const userNameRef = useRef("");
  const userEmailRef = useRef("");
  const userMemoRef = useRef("");
  const userPasswordRef = useRef("");
  const userRePasswordRef = useRef("");
  const userAuthorityRef = useRef(1);
  const [userClearances, setUserClearances] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const displayChips = (clearances, index) => {
    return (
      <Chip
        label={CHIP_NAME_LIST[index]}
        className="chip"
        color={clearances.includes(index+1) ? CHIP_COLOR_LIST[index] : "default"}
        variant={clearances.includes(index+1) ? "contained" : "outlined"}
        onClick={() => handleClickChip(index)}
        sx={{
          mt: 1,
          ml: 1,
        }}
      />
    );
  };

  const handleClickChip = (index) => {
    setUserClearances((oldClearances) => {
      let newClearances = [];
      if (oldClearances.includes(index+1)) {
        newClearances = oldClearances.filter((oldIndex) => oldIndex !== index+1);
        if (index === 0) {
          userAuthorityRef.current = 1;
        }
      } else {
        newClearances = [...oldClearances, index+1];
        if (index === 0) {
          userAuthorityRef.current = 2;
        }
      }
      return newClearances;
    });
  };

  const createUserClearances = (clearances) => {
    return apiClient
      .post(`/user_clearances/`, clearances)
      .then((res) => {
        console.log("Create new clearances", res);
      })
      .catch((err) => {
        console.log("Err POST user_clearances :", err);
      });
  }

  const postNewUser = (newUser) => {
    apiClient
      .post("/users/createUser/", newUser)
      .then((res) => {
        console.log("Post new user:", res);
        return createUserClearances(userClearances.map(clearance => {
          return {
            user_id: res.data.id,
            clearance_id: clearance,
            is_deleted: false
          }
        }))
      }).then((res) => {
        console.log("Post new user_clearances:");
        fetchUserList();
        setUserClearances([]);
        handleCloseNewRes();
      })
      .catch((err) => {
        console.log("Err POST user :", err);
      });
  };

  const handleClickSave = () => {
    if (userPasswordRef.current.value !== userRePasswordRef.current.value) {
      enqueueSnackbar("パスワードが一致しません。", {
        variant: "error",
        autoHideDuration: 5000,
      });
      console.log(userPasswordRef.current, userRePasswordRef.current);

      return;
    }
    const newUser = {
      name: userNameRef.current.value,
      email: userEmailRef.current.value,
      authority: userAuthorityRef.current,
      password: userPasswordRef.current.value,
      clearances: userClearances.join(),
      memo: userMemoRef.current.value,
    };

    postNewUser(newUser);

    console.log(newUser);
  };

  return (
    <Dialog
      className="new_res_dialog"
      open={isNewResOpen}
      onClose={handleCloseNewRes}
      maxWidth={"xl"}
    >
      <DialogTitle>新規RES登録画面</DialogTitle>
      <DialogContent className="content">
        <Box className="inner">
          <Grid container>
            <Grid item xs={6} className="default_info">
              <Typography variant="h6">RES情報</Typography>
              <Box>
                <TextField
                  required
                  label="氏名"
                  className="name"
                  inputRef={userNameRef}
                />
              </Box>
              <Box>
                <TextField
                  required
                  label="メールアドレス"
                  className="email"
                  inputRef={userEmailRef}
                />
              </Box>
              <Box>
                <TextField
                  required
                  label="パスワード"
                  className="password"
                  inputRef={userPasswordRef}
                />
              </Box>
              <Box>
                <TextField
                  required
                  label="パスワード確認用"
                  className="re_password"
                  inputRef={userRePasswordRef}
                />
              </Box>
              <Box>
                <TextField
                  label="備考欄"
                  className="memo"
                  rows={4}
                  multiline
                  inputRef={userMemoRef}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">対応可能工程</Typography>
              <Box className="clearances">
                <Box className="clearances_inner">
                  {CHIP_LIST.map((index) => {
                    return displayChips(userClearances, index);
                  })}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseNewRes}
          color={"inherit"}
          variant="outlined"
        >
          キャンセル
        </Button>
        <Button onClick={handleClickSave} color="primary" variant="outlined">
          登録
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResNewDialog;
