import { Box, Button, Typography } from "@mui/material";
import { MODE_INDEX } from "../../constants/constants";

const Title = ({ dmMode, setDmMode, setIsCreateProjectOpen }) => {
  const newProjectClicked = () => {
    setIsCreateProjectOpen(true);
  };

  const resManageClicked = () => {
    setDmMode(MODE_INDEX["res"]);
  };

  return (
    <Box
      sx={{
        width: "95%",
        height: 80,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          width: "240px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {dmMode !== MODE_INDEX["main"] && (
          <Button
            variant="outlined"
            onClick={() => setDmMode(MODE_INDEX["main"])}
          >
            案件選択へ戻る
          </Button>
        )}
      </Box>
      {dmMode === MODE_INDEX["main"] && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button variant="outlined" onClick={() => newProjectClicked()}>
            <Typography>新規案件作成</Typography>
          </Button>
          <Button
            variant="outlined"
            sx={{ ml: 2 }}
            onClick={() => resManageClicked()}
          >
            <Typography>RES管理ページ</Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Title;
