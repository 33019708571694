import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Project from "./Project";

const Projects = ({
  projectList,
  setProject,
  setResAssignModalOpen,
  setDmMode,
  isGettingProjects,
  setIsDetailOpen,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">案件選択</TableCell>
            <TableCell align="center">作成日時</TableCell>
            <TableCell align="center">アサイン</TableCell>
            <TableCell align="center">分類設定</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isGettingProjects ? (
            <>案件を取得中です...</>
          ) : (
            projectList.map((project) => (
              <Project
                setResAssignModalOpen={setResAssignModalOpen}
                project={project}
                setProject={setProject}
                key={project.id}
                setDmMode={setDmMode}
                setIsDetailOpen={setIsDetailOpen}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Projects;
