import { Box, Button, CircularProgress, Typography } from "@mui/material";

const Loading = ({
  handleConfirm,
  title,
  isLoading,
  isFinished,
  handleFinish,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          mt: 10,
        }}
      >
        {isFinished ? (
          <Typography mt={5} variant="h4" sx={{ textAlign: "center" }}>
            すべての回答は終了しています
          </Typography>
        ) : !isLoading || title === undefined ? (
          <Typography mt={5} variant="h4" sx={{ textAlign: "center" }}>
            設問を準備しています...
          </Typography>
        ) : (
          <Typography mt={5} variant="h4" sx={{ textAlign: "center" }}>
            {isFinished ? "すべての回答は終了しています" : title}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          mt: 10,
        }}
      >
        <Button
          variant="contained"
          onClick={!isFinished ? handleConfirm() : handleFinish()}
          disabled={!isLoading}
          sx={{
            position: "relative",
            width: 320,
            height: 64,
          }}
        >
          {isFinished ? (
            <Typography>クリックして終了する</Typography>
          ) : !isLoading || title === undefined ? (
            <Box
              sx={{
                position: "absolute",
                top: 10,
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <Typography>クリックして回答を開始する</Typography>
          )}
        </Button>
      </Box>
    </>
  );
};

export default Loading;
