import { Box, InputLabel, TextField } from "@mui/material";
import React from "react";

const AnswerEviComponent = ({ proposition }) => {
  return (
    <Box className="evidence__container">
      <div className="evidence">
        <InputLabel className="evi_label" htmlFor={proposition.id + "evi"}>
          根拠
        </InputLabel>
        <TextField
          className="evi_text"
          id={proposition.id + "evi"}
          type="text"
          multiline={true}
          maxRows={10}
        />
      </div>

      <div className="message">
        <InputLabel className="evi_label" htmlFor={proposition.id + "mes"}>
          申送
        </InputLabel>
        <TextField
          className="evi_text"
          id={proposition.id + "evi"}
          type="text"
        />
      </div>
    </Box>
  );
};

export default AnswerEviComponent;
