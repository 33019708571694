import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { apiClient } from "../../API/apiClient";
import ClassyTitleInfo from "./ClassyTitleInfo";
import Evidence from "./Evidence";
import InputModule from "./InputModule";
import RadioModule from "./RadioModule";
import Section from "./Section";

const Classification = ({ project }) => {
  const createDate = new Date(project.create_date);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isGettingPropositions, setIsGettingPropositions] = useState({
    flag: false,
  });
  const [isCheckSectionDeleteOpen, setIsCheckSectionDeleteOpen] =
    useState(false);
  const [currentPropositions, setCurrentPropositions] = useState([
    {
      description: "",
      id: 0,
      number: 0,
      project_id: 0,
      proposition_section: [
        {
          id: 0,
          module_type: 0,
          title: "",
          flag: false,
          description: null,
          number: 0,
          section_id: 0,
        },
      ],
    },
  ]);

  const postSection = () => {
    apiClient
      .post("/sections/", {
        project_id: project.id,
      })
      .then((res) => {
        console.log("Success to post section:", res.data);
        const newSection = res.data;

        setCurrentPropositions((prev) => [
          ...prev,
          {
            id: newSection.id,
            description: newSection.description,
            number: newSection.number,
            project_id: project.id,
            title: newSection.title,
            proposition_section: [
              {
                id: null,
                module_type: 1,
                title: null,
                flag: false,
                description: null,
                number: null,
                section_id: 1,
              },
            ],
          },
        ]);
      })
      .catch((err) => {
        console.log("Section post Err:", err);
      });
  };

  const handleClickAddSection = () => {
    postSection();
  };

  const fetchUpdateSection = (section, id) => {
    apiClient
      .put(`/section/${id}/`, { ...section })
      .then((res) => {
        enqueueSnackbar(section.title + "は正常に保存されました！", {
          variant: "success",
          autoHideDuration: 2500,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status !== 401) {
          enqueueSnackbar(
            section.title +
              "を保存できませんでした、再度試行してください。" +
              `Code: ${err.response.status} `,
            { variant: "warning", autoHideDuration: 10000 }
          );
        }
      });
  };

  const fetchPostProposition = (proposition) => {
    apiClient
      .post("/propositions/", { ...proposition })
      .then((res) => {
        enqueueSnackbar(proposition.title + "は正常に保存されました！", {
          variant: "success",
          autoHideDuration: 2500,
        });
      })
      .catch((err) => {
        console.log("Err for saving proposition:", err);
        if (err.response.status !== 401) {
          enqueueSnackbar(
            proposition.title +
              "を保存できませんでした、再度試行してください。" +
              `Code: ${err.response.status} `,
            { variant: "warning", autoHideDuration: 10000 }
          );
        }
      });
  };

  const fetchPutProposition = (proposition, id) => {
    apiClient
      .put(`/proposition/${id}/`, {
        ...proposition,
      })
      .then((res) => {
        console.log("Put propositions:", res);
      })
      .catch((err) => {
        console.log("Err for saving proposition:", err);
        if (err.response.status !== 401) {
          enqueueSnackbar(
            proposition.title +
              "を保存できませんでした、再度試行してください。" +
              `Code: ${err.response.status} `,
            { variant: "warning", autoHideDuration: 10000 }
          );
        }
      });
  };

  const handleClickSaveQuestions = () => {
    currentPropositions.map((section, section_index) => {
      const updateSection = {
        title: section.title,
        number: section_index + 1,
        description: section.description,
        project_id: project.id,
      };
      fetchUpdateSection(updateSection, section.id);
      console.log(section);
      section.proposition_section.map((proposition, index) => {
        const newProposition = {
          number: index + 1,
          title: proposition.title,
          module_type: proposition.module_type,
          description: proposition.description,
          flag: false,
          section_id: section.id,
        };
        if (proposition.id === null) {
          if (proposition.title === null) {
            enqueueSnackbar(
              `セクション${section_index + 1}:設問${
                index + 1
              }、タイトルを入力してください`,
              { variant: "error", autoHideDuration: 10000 }
            );
          } else {
            fetchPostProposition(newProposition);
          }
        } else {
          fetchPutProposition(newProposition, proposition.id);
        }
      });
    });
  };

  const handleClickAddQuestion = (section_num) => {
    setCurrentPropositions((prev) => {
      const currentData = [...prev];
      currentData[section_num] = {
        ...prev[section_num],
        proposition_section: [
          ...prev[section_num].proposition_section,
          {
            id: null,
            module_type: 1,
            title: null,
            flag: false,
            description: null,
            number: null,
            section_id: 1,
          },
        ],
      };
      return currentData;
    });
    console.log(currentPropositions);
  };

  const changeQuestion = (index, newProp, section_num) => {
    setCurrentPropositions((prev) => {
      const currentData = [...prev];
      currentData[section_num].proposition_section = currentData[
        section_num
      ].proposition_section.filter((proposition, i) => {
        if (i === index) {
          return newProp;
        } else {
          return proposition;
        }
      });
      return currentData;
    });
  };

  const changeSection = (section_index, newSection) => {
    setCurrentPropositions((prev) => {
      const currentData = [...prev];
      currentData[section_index] = newSection;
      return currentData;
    });
  };

  const getSectionsWithPropositions = () => {
    apiClient
      .get(`/get_sections_with_propositions/${project.id}`)
      .then((result) => {
        console.log("Get proposition with section:", result);
        setCurrentPropositions(result.data);
        setIsGettingPropositions(true);
      })
      .catch((err) => {
        console.log("Err proposition with section:", err);
      });
  };

  useEffect(() => {
    getSectionsWithPropositions();
  }, [project.id]);

  const moveQuestion = (movingValue, currentQuestionIndex, section_num) => {
    setCurrentPropositions((prev) => {
      const currentData = [...prev];
      if (
        currentData[section_num].proposition_section[
          currentQuestionIndex + movingValue
        ]
      ) {
        //　移動したいデータを保存する
        const moveToData =
          currentData[section_num].proposition_section[currentQuestionIndex];
        // 移動先のデータを移動データに上書き
        currentData[section_num].proposition_section[currentQuestionIndex] =
          currentData[section_num].proposition_section[
            currentQuestionIndex + movingValue
          ];
        // 保存したデータを移動先に上書き
        currentData[section_num].proposition_section[
          currentQuestionIndex + movingValue
        ] = moveToData;
      } else {
        enqueueSnackbar("おや？そちらには移動できないみたいです", {
          autoHideDuration: 2000,
        });
      }
      return currentData;
    });
  };

  const deleteQuestion = (deleteIndex, section_num) => {
    if (
      currentPropositions[section_num].proposition_section[deleteIndex].id !==
      null
    ) {
      apiClient
        .delete(
          `/proposition/${currentPropositions[section_num].proposition_section[deleteIndex].id}/`
        )
        .then((res) => {
          console.log(res);
          enqueueSnackbar(
            currentPropositions[section_num].proposition_section[deleteIndex]
              .title + "は正常に削除されました！",
            { variant: "success", autoHideDuration: 5000 }
          );
          deleteQuestionFromPropositions(section_num, deleteIndex);
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(
            currentPropositions[section_num].proposition_section[deleteIndex]
              .title +
              "を削除できませんでした、再度試行してください。" +
              `Code: ${err.response.status} `,
            { variant: "warning", autoHideDuration: 10000 }
          );
        });
    } else {
      console.log("ID is not found", currentPropositions);
      deleteQuestionFromPropositions(section_num, deleteIndex);
    }
  };

  const deleteQuestionFromPropositions = (section_num, deleteIndex) => {
    setCurrentPropositions((prev) => {
      const currentData = [...prev];
      currentData[section_num].proposition_section = currentData[
        section_num
      ].proposition_section.filter((proposition, i) => {
        if (i !== deleteIndex) {
          return proposition;
        }
      });
      console.log(currentData);

      return currentData;
    });
  };

  const filterPropositions = (propositions, deleteIndex) => {
    const result = propositions.filter((proposition, i) => {
      if (i !== deleteIndex) {
        return proposition;
      }
    });

    return result;
  };

  const delSectionByID = (id) => {
    apiClient
      .delete(`/section/${id}`)
      .then((res) => {
        console.log("Success to delete section:", res);
      })
      .catch((err) => {
        console.log("Err delete section:", err);
      });
  };

  const handleCloseCheckSection = () => {
    setIsCheckSectionDeleteOpen({ flag: false });
  };

  const handleClickDeleteSection = (sectionIndex, section_id) => {
    setIsCheckSectionDeleteOpen({
      sectionIndex: sectionIndex,
      section_id: section_id,
      flag: true,
    });
  };

  const deleteSection = () => {
    if (isCheckSectionDeleteOpen.section_id) {
      delSectionByID(isCheckSectionDeleteOpen.section_id);
      setCurrentPropositions((prev) =>
        prev.filter((e, i) => i !== isCheckSectionDeleteOpen.sectionIndex)
      );
      handleCloseCheckSection();
    }
  };

  const AnswerModuleComponent = ({ module_type, proposition, source_id }) => {
    if (module_type === 1) {
      return (
        <>
          <RadioModule proposition={proposition} />
          <Evidence proposition={proposition} />
        </>
      );
    } else if (module_type === 2) {
      return (
        <>
          <InputModule proposition={proposition} />
          <Evidence proposition={proposition} />
        </>
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <Box className="classy__container" sx={{}}>
        <Dialog
          open={isCheckSectionDeleteOpen.flag}
          onClose={handleCloseCheckSection}
        >
          <DialogTitle>
            {"このセクションを本当に削除してよろしいですか？"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              削除してしまったセクションを戻すことはできません。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="info" onClick={handleCloseCheckSection}>
              Cancel
            </Button>
            <Button color="error" variant="outlined" onClick={deleteSection}>
              DELETE
            </Button>
          </DialogActions>
        </Dialog>
        <ClassyTitleInfo
          project={project}
          createDate={createDate}
          handleClickSaveQuestions={handleClickSaveQuestions}
        />
        {!isGettingPropositions ? <></> : currentPropositions ? <></> : null}

        {!isGettingPropositions ? (
          <>設問を取得中です...</>
        ) : (
          currentPropositions.map((section, section_index) => (
            <Section
              key={section_index}
              section={section}
              section_index={section_index}
              handleClickAddQuestion={handleClickAddQuestion}
              deleteQuestion={deleteQuestion}
              changeQuestion={changeQuestion}
              handleClickDeleteSection={handleClickDeleteSection}
              changeSection={changeSection}
              moveQuestion={moveQuestion}
              currentPropositions={currentPropositions}
              setCurrentPropositions={setCurrentPropositions}
            />
          ))
        )}

        {currentPropositions === [] ? (
          <Box className="addSection">
            <AddCircleIcon
              className="addSection__button"
              onClick={() => handleClickAddSection()}
            />
            <Typography
              className="addSection__text"
              onClick={() => handleClickAddSection()}
            >
              セクションを追加する
            </Typography>
          </Box>
        ) : (
          <Box className="newSection">
            <Button
              className="newSection__button"
              variant="contained"
              onClick={() => handleClickAddSection()}
            >
              新しいセクションを作成する
            </Button>
          </Box>
        )}
      </Box>
      <Box className="preview__contaier">
        <Box className="preview__inner">
          {currentPropositions.map((section, section_index) => (
            <Box className="section__container">
              <Typography className="title">
                セクション{section.number}：{section.title}
              </Typography>
              <Typography className="desc">{section.description}</Typography>
              <Box className="section__inner">
                {section.proposition_section.map((proposition, prop_index) => (
                  <Box className="question__block" key={proposition.id}>
                    <Typography className="prop-title">
                      {proposition.title}
                    </Typography>
                    {proposition.description && (
                      <Typography className="prop-description">
                        ※{proposition.description}
                      </Typography>
                    )}
                    <AnswerModuleComponent
                      module_type={proposition.module_type}
                      proposition={proposition}
                      source_id={0}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
          <Button className="save_button" variant="contained">
            保存
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Classification;
