import { Button, TableCell, TableRow } from "@mui/material";
import React from "react";
import { MODE_INDEX } from "../../constants/constants";

const Project = ({
  setResAssignModalOpen,
  project,
  setProject,
  setDmMode,
  setIsDetailOpen,
}) => {
  const createDate = new Date(project.create_date);

  const selectProject = () => {
    setProject(project);
    setIsDetailOpen(true);
  };

  const resAssignClicked = () => {
    setResAssignModalOpen(true);
    setProject(project);
  };

  const classificationClicked = () => {
    setProject(project);
    setDmMode(MODE_INDEX["classy"]);
  };

  return (
    <TableRow>
      <TableCell align="center">{project.id}</TableCell>
      <TableCell align="center">
        <Button sx={{}} onClick={() => selectProject()}>
          {project.name}
          {/* <DriveFileRenameOutlineIcon /> */}
        </Button>
      </TableCell>
      <TableCell align="center">{createDate.toLocaleString("ja-JP")}</TableCell>
      <TableCell align="center">
        <Button
          variant="contained"
          color="success"
          onClick={() => resAssignClicked()}
        >
          RES割当
        </Button>
      </TableCell>
      <TableCell align="center">
        <Button
          variant="contained"
          color="success"
          onClick={() => classificationClicked(project)}
        >
          分類項目
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default Project;
